<template>
  <!-- prettier-ignore -->
  <basic-context-menu
    v-model="basketWidgetOpen"
    class="basket__widget"
    has-full-width-on-mobile
  >
    <template #trigger>
      <button
        class="trigger trigger-btn"
        aria-label="basket toggle"
        tabindex="0"
        @click="basketWidgetOpen = !basketWidgetOpen"
      >
        <the-icon
          art="light"
          name="cart-shopping"
        />
        <div
          class="badge"
          v-text="selectedProductCount"
        />
      </button>
    </template>

    <template #content>
      <basket-container
        @close="basketWidgetOpen = false"
      />
    </template>
  </basic-context-menu>
</template>

<script setup>
import { ref } from 'vue'

import { BasicContextMenu } from '@/components/Basic'
import BasketContainer from '@/components/BasketWidget/BasketContainer'

import usePersonDetails from '@/hooks/usePersonDetails'

// HOOKS
const { selectedProductCount } = usePersonDetails()

// DATA
const basketWidgetOpen = ref(false)
</script>

<style>
.badge {
  position: absolute;
  top: 4px;
  right: 0;
  background-color: var(--primary);
  color: var(--on-attention);
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  box-sizing: content-box;
  box-shadow: 0 0 0 3px var(--surface-ext-b-low);

  .trigger {
    &:focus-visible {
      outline: 2px solid var(--dvp-btn-c-outline);
      outline-offset: var(--fixed-spacing-fix-01);
      border-radius: var(--fixed-border-radius-fix-full);
    }
  }
}
</style>
