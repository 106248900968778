<template>
  <div class="header the-grid">
    <div class="header__logo">
      <a :href="dotCom">
        <img
          alt="logo"
          :src="logo"
        />
      </a>
    </div>

    <div
      v-if="metaOptions?.navigation === undefined && metaOptions?.navigation !== false"
      class="header__navigation"
    >
      <slot name="navigation" />
    </div>

    <div
      v-if="!metaOptions?.headerDisabled"
      class="header__actions"
    >
      <div class="action__trigger">
        <debug-button v-if="isTestEnv" />

        <basic-modal
          id="debug-modal"
          ref="debugModalRef"
          size="maxi"
          is-alert
          modal-title="Debug Actions"
          type="raw"
        >
          <debug-menu @close="debugModalRef.close()" />
        </basic-modal>
      </div>

      <div class="action__trigger">
        <basket-wrapper />
      </div>

      <div class="action__trigger">
        <basic-context-menu v-model="sessionMenuOpen">
          <template #trigger>
            <button
              class="trigger trigger-btn"
              @click="sessionMenuOpen = !sessionMenuOpen"
            >
              <the-icon
                art="light"
                name="user"
              />
            </button>
          </template>

          <template #content>
            <session-menu />
          </template>
        </basic-context-menu>
      </div>
    </div>

    <contact-data-modal
      ref="onboardingModalRef"
      @close="onboardingModalRef.modalRef.close()"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import { events$ } from '@/services'

import useI18n from '@/hooks/useI18n'

import BasketWrapper from '@/components/BasketWidget/BasketWrapper'
import DebugMenu from '@/components/Debug/Menu'
import DebugButton from '@/components/Debug/Button'
import SessionMenu from '@/components/Header/SessionMenu'
import { BasicContextMenu, BasicModal } from '@/components/Basic'
import ContactDataModal from '@/components/Onboarding/ContactDataModal'

import { EVENT_ONBOARDING } from '@/config/events'
import logo from '@/assets/logo.svg'

// HOOKS
const { selectedLanguage } = useI18n()

// INIT
const isTestEnv = import.meta.env.VITE_ENV_KEY !== 'PRD'
defineProps({
  metaOptions: {
    type: Object,
    default: () => {},
  },
})

// DATA
const sessionMenuOpen = ref(false)
const onboardingModalRef = ref()
const debugModalRef = ref()

// COMPUTED
const dotCom = computed(() => {
  if (import.meta.env.DEV) {
    return `${window.location.protocol}//${window.location.host}`
  }
  return `${window.location.protocol}//${window.location.host}/${selectedLanguage.value}`
})

// LIFECYCLE HOOKS
onMounted(() => {
  events$.on(EVENT_ONBOARDING.OPEN, () => {
    if (onboardingModalRef.value) {
      onboardingModalRef.value?.modalRef.open()
    }
  })
})
</script>

<style scoped>
@keyframes firestarter {
  0% {
    opacity: 1;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }
  5% {
    opacity: 0.3;
    --fa-primary-color: orange;
    --fa-secondary-color: yellow;
  }
  15% {
    opacity: 0.7;
    --fa-primary-color: darkorange;
    --fa-secondary-color: red;
  }
  25% {
    opacity: 0.8;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }
  35% {
    opacity: 0.25;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }
  45% {
    opacity: 0.6;
    --fa-primary-color: red;
    --fa-secondary-color: orange;
  }
  55% {
    opacity: 0.4;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }

  100% {
    opacity: 1;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }
}

.header {
  display: grid;
  grid-template-areas:
    'logo actions'
    'navigation navigation';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40px 1fr;
  row-gap: var(--fixed-spacing-fix-04);
  background-color: var(--surface-ext-b-low);
  transition: all 0.5s linear;
  padding: var(--dotcom-responsive-spacing-res-s) 0;
  align-items: baseline;
}

.header__logo {
  grid-area: logo;

  img {
    height: 28px;
  }
}

.header__actions {
  grid-area: actions;
  display: flex;
  flex-direction: row;
  column-gap: var(--fixed-spacing-fix-06);
  justify-content: flex-end;
}

.action__trigger {
  height: 30px;

  .trigger,
  :deep(.trigger) {
    background: none;
    border: none;
    font-size: 28px;
    color: var(--on-surface);
    display: inline-block;

    &:hover {
      cursor: pointer;
      color: var(--Interaction-States-pressed-default-a);
    }

    &:focus-visible {
      outline: 2px solid var(--dvp-btn-c-outline);
      outline-offset: var(--fixed-spacing-fix-01);
      border-radius: var(--fixed-border-radius-fix-full);
    }
  }

  :deep(.menu) {
    min-width: 280px;
  }

  :deep(button) {
    padding: 0;
    padding-block: 0;
    padding-inline: 0;
  }
}

.header__navigation {
  grid-area: navigation;
}

@media (--v-medium) {
}

@media (--v-large) {
  .header {
    grid-template-areas: 'logo navigation actions';
    grid-template-columns: 150px 1fr 150px;
    grid-template-rows: 1fr;
    column-gap: var(--fixed-spacing-fix-12);

    &__logo,
    &__meta {
      margin-top: 16px;
    }
  }
}

@media (--v-wide) {
  .header {
    column-gap: var(--fixed-spacing-fix-14);
  }
}

@media (--v-ultra) {
  .header {
    column-gap: var(--fixed-spacing-fix-16);
  }
}

@media (--v-max) {
  .header {
    column-gap: var(--fixed-spacing-fix-20);
  }
}
</style>
