<template>
  <audio
    ref="audioRef"
    loop
  >
    <source
      :src="FIREBALL"
      type="audio/ogg"
    />
  </audio>

  <button
    class="button debug-btn"
    data-a11y-dialog-show="debug-modal"
    @mouseover="startIt"
    @mouseleave="stopIt"
  >
    <the-icon
      class="trigger"
      art="duo"
      name="raccoon"
    />

    <the-icon
      class="flame"
      art="duo"
      name="fire-flame"
    />

    <div class="flame" />
  </button>
</template>

<script setup>
import { ref } from 'vue'

import FIREBALL from '@/assets/FIREBALL.ogg'

// DATA
const audioRef = ref()

// METHODS
function startIt() {
  if (audioRef.value.paused) {
    audioRef.value.volume = 0.5
    audioRef.value.play()
  }
}

function stopIt() {
  if (!audioRef.value.paused) {
    audioRef.value.pause()
  }
}
</script>

<style scoped>
@keyframes firestarter {
  0% {
    opacity: 1;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }
  5% {
    opacity: 0.3;
    --fa-primary-color: orange;
    --fa-secondary-color: yellow;
  }
  15% {
    opacity: 0.7;
    --fa-primary-color: darkorange;
    --fa-secondary-color: red;
  }
  25% {
    opacity: 0.8;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }
  35% {
    opacity: 0.25;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }
  45% {
    opacity: 0.6;
    --fa-primary-color: red;
    --fa-secondary-color: orange;
  }
  55% {
    opacity: 0.4;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }

  100% {
    opacity: 1;
    --fa-primary-color: yellow;
    --fa-secondary-color: orange;
  }
}

.debug-btn {
  background: none;
  border: none;
  font-size: 28px;

  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  .trigger {
    transition: all 0.3 ease-in-out;
  }

  .flame {
    display: none;
  }

  &:hover {
    transform: scale(2);

    .trigger {
      --fa-primary-color: purple;
      --fa-secondary-color: var(--on-surface);
      --fa-secondary-opacity: 0.7;
    }

    .flame {
      display: inline-block;
      position: absolute;
      top: 12px;
      font-size: 14px;
      right: -0px;
      transform: rotate(-90deg);
      animation: 0.2s firestarter infinite;
    }
  }
}
</style>
